@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap");

@layer components {
  .tiny-header {
    @apply font-sans-light py-4 text-micro;
  }
}

@font-face {
  font-family: "Monaspace Krypton";
  src: url("../public/fonts/MonaspaceKryptonVarVF[wght\,wdth\,slnt].ttf")
    format("truetype");
}

@font-face {
  font-family: "Monaspace Neon";
  src: url("../public/fonts/MonaspaceNeonVarVF[wght\,wdth\,slnt].ttf")
    format("truetype");
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

::selection {
  background-color: #000000; /* Light green color */
  color: #ffffff; /* Black text for contrast */
}

::-moz-selection {
  background-color: #000000; /* Light green color for Firefox */
  color: #ffffff; /* Black text for contrast */
}
